// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:433ecef4-67a3-4c9e-aecf-852a96ed337b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oSes0eaG5",
    "aws_user_pools_web_client_id": "78vdl6552orb8tdenkulv2ah6g",
    "oauth": {},
    "aws_content_delivery_bucket": "oliviastafford-20200422210616-hostingbucket-olivia",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2zgsh9be61361.cloudfront.net"
};


export default awsmobile;
