import React from 'react';
import './App.css';
import { withAuthenticator } from 'aws-amplify-react'

function App() {
  return (
    <div className="linkbox1">
        <a href="music2.html">
          Olivia Music
        </a>
    </div>
  )
}

export default withAuthenticator(App);
